




















import {Component, Vue} from "vue-property-decorator";
import {Meta} from "../decorators";


@Component
@Meta({
    title: "API"
})
export default class Swagger extends Vue {

    public swaggerUrl: string | undefined;


    constructor() {
        super();
        this.swaggerUrl = `${process.env.VUE_APP_SWAGGER}`;
    }

}















import {Component, Vue} from "vue-property-decorator";
import ExchangeData from "@/components/ExchangeData.vue";
import MapOfExchanges from "@/components/MapOfExchanges.vue";
import ListOfExchanges from "@/components/ListOfExchanges.vue";
import {MetaMethod} from "@/decorators";
import {Action, State} from "vuex-class";
import {Loaded} from "@/store";

@Component({
    components: {
        ExchangeData,
        MapOfExchanges,
        ListOfExchanges
    }
})
export default class LatestExchange extends Vue {
    @Action fetchCurrencyData!: (isoCode: string) => void;
    @Action fetchCurrencies!: () => void;
    @Action hideExchangeDialog!: () => void;
    @State exchangeDialog!: { loading: boolean; show: boolean };
    @State current!: any;
    @State currencies!: Loaded<string[]>;
    tabModel: number = 0;
    isSmall: boolean;
    baseUrl: string | undefined;

    constructor() {
        super();
        this.isSmall = window.innerWidth < 600;
        this.baseUrl = process.env.BASE_URL;
    }

    @MetaMethod
    meta() {
        return {
            title: `Cotización de ${this.current.currency}`,
            titleTemplate: undefined
        };
    }

    mounted() {
        let finalCurrency: string = "USD";
        const str = this.$route.query.moneda;
        if (Array.isArray(str)) finalCurrency = str[0] || "USD";
        if (typeof str === "string") finalCurrency = str;
        this.fetchCurrencyData(finalCurrency);
        this.fetchCurrencies();
    }

}

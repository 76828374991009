








































































































import {Component, Vue, Watch} from "vue-property-decorator";
import ExchangeData from "@/components/ExchangeData.vue";
import {Meta} from "@/decorators";
import {Action, Getter, State} from "vuex-class";
import {Loaded} from "@/store";
import {QueryResponseDetail} from "@/api/ExchangeAPI";
import {Place} from "../api/ExchangeAPI";

@Component
export default class ListOfExchanges extends Vue {
    @State currencies!: Loaded<string[]>;
    @State current!: { currency: string; amount: number };

    @Action showExchangeData!: () => void;
    @Action setCurrentCurrency!: (isoCode: string) => void;
    @Action setCurrentPlace!: (place: Place) => void;
    @Action clearCurrentPlace!: () => void;

    @Getter filteredCurrencies!: Loaded<ExchangeData[]>;
    @Getter currentPlaces!: Place[];

    placesWithEmpty: Place[] = [];

    headers: Array<{
        text?: string;
        value?: string;
        align?: "left" | "center" | "right";
        sortable?: boolean;
        class?: string[] | string;
        width?: string;
    }>;
    pagination: any;
    isSmall: boolean;
    private baseUrl: string | undefined;

    constructor() {
        super();

        this.baseUrl = process.env.BASE_URL;
        this.isSmall = window.innerWidth < 600;
        this.headers = [
            {
                text: "",
                align: "left",
                sortable: false,
                value: "placeName",
                width: "35%",
                class: "place"
            }, {
                text: "Compra",
                value: "purchasePrice",
                sortable: true,
                class: "text-xs-right"
            }, {
                text: "Venta",
                value: "salePrice",
                sortable: true,
                class: "text-xs-right"
            }
        ];
        if (!this.isSmall)
            this.headers.push({text: " ", value: "", sortable: false});

        this.pagination = {
            sortBy: "purchasePrice",
            descending: true,
            rowsPerPage: -1
        };
    }

    @Watch("currentPlaces", {immediate: true})
    setLocalPlace(places: Place[]) {
        this.placesWithEmpty = [{
            name: "Todos",
            id: -1
        } as Place].concat(places);
    }
}

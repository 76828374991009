



























































import { Component, Prop, Vue } from "vue-property-decorator";
import { Loaded } from "@/store";
import { ExchangeDataDTO } from "@/api/ExchangeAPI";
import { Getter, State, Action } from "vuex-class";

@Component
export default class MapOfExchanges extends Vue {
  @Getter branchesWithLocation!: Loaded<ExchangeDataDTO[]>;
  @State current!: { currency: string; amount: number };
  @Action showExchangeData!: () => void;
  height: number = window.innerWidth < 600
    ? window.innerHeight - 46 - 56 - 20
    : 800;

  infoWindowOptions = {
    pixelOffset: {
      width: 0,
      height: -35
    }
  };

  selected: string = "";

  searchLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };

          const map = (this.$refs.mapRef as any).$mapObject;
          map.panTo(pos);
          map.setZoom(14);
        },
        (err) => {
          alert(`Error (${err.code}): ${err.message}`);
        }
      );
    } else {
      // Browser doesn't support Geolocation
      alert("Imposible usar ubicación");
    }
  }
}












import {Component, Vue} from "vue-property-decorator";

@Component({})
export default class BackendDown extends Vue {
    baseUrl: string | undefined;


    constructor() {
        super();
        this.baseUrl = process.env.BASE_URL;
    }

}
